import { useState, useEffect } from "react";
import { getBasicInfos } from "../../services/basicInfosService";
import { getComponents } from "../../services/componentsService";
import { getDataset } from "../../services/datasetService";

export const useDescription = () => {
  const [description, setDescription] = useState("");

  return { description, setDescription };
};

export const useDatasets = () => {
  const [datasets, setDatasets] = useState<ResponseWrapper<
    EntityWrapper<Dataset>[]
  > | null>(null);
  const [dataset, setDataset] = useState("");
  const [selectedDatasets, setSelectedDatasets] = useState<
    EntityWrapper<Dataset>[]
  >([]);

  useEffect(() => {
    getDataset().then(setDatasets).catch(console.error);
  }, [dataset]);

  return {
    datasets,
    dataset,
    setDataset,
    selectedDatasets,
    setSelectedDatasets,
  };
};

export const useBasicInfos = () => {
  const [basicInfos, setBasicInfos] = useState<ResponseWrapper<
    EntityWrapper<BasicInfo>[]
  > | null>(null);
  const [basicInfo, setBasicInfo] = useState("");
  const [selectedBasicInfos, setSelectedBasicInfos] = useState<
    EntityWrapper<BasicInfo>[]
  >([]);

  useEffect(() => {
    getBasicInfos().then(setBasicInfos).catch(console.error);
  }, [basicInfo]);

  return {
    basicInfos,
    basicInfo,
    setBasicInfo,
    selectedBasicInfos,
    setSelectedBasicInfos,
  };
};

export const useComponents = () => {
  const [components, setComponents] = useState<ResponseWrapper<
    EntityWrapper<Component>[]
  > | null>(null);
  const [component, setComponent] = useState("");
  const [selectedComponents, setSelectedComponents] = useState<
    EntityWrapper<Component>[]
  >([]);

  useEffect(() => {
    getComponents().then(setComponents).catch(console.error);
  }, [component]);

  return {
    components,
    component,
    setComponent,
    selectedComponents,
    setSelectedComponents,
  };
};

export const useImage = () => {
  const [images, setImages] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  return { images, setImages, files, setFiles };
};
