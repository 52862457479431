import { Box, TextField } from "@mui/material";
import BaseDialog from "./BaseDialog";
import * as React from "react";

interface AddInstructionDialogProps {
  handleClose: () => void;
  handleAdd: (title: string, content: string) => void;
  open: boolean;
}

export default function AddInstructionDialog({
  handleClose,
  handleAdd,
  open,
}: AddInstructionDialogProps) {
  const [title, setTitle] = React.useState<string>("");
  const [content, setContent] = React.useState<string>("");

  return (
    <BaseDialog
      handleClose={handleClose}
      open={open}
      title="Add Instruction"
      positiveTitle="Add"
      handlePositive={() => {
        handleAdd(title, content);
      }}
    >
      <Box sx={{ minWidth: "512px", mt: 1 }}>
        <TextField
          id="outlined-title"
          label="Title"
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <TextField
          id="outlined-content"
          label="Content"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={content}
          onChange={(e) => setContent(e.target.value)}
        />
      </Box>
    </BaseDialog>
  );
}
