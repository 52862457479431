import { useLocation } from "react-router-dom";
import TopAppBar from "../../components/TopAppBar";
import { instructionsAtom } from "../../atoms/instructionsAtom";
import { useRecoilState } from "recoil";
import {
  deleteInstruction,
  getInstructions,
  postInstruction,
  updateInstruction,
} from "../../services/instructionsService";
import { useEffect } from "react";
import AddInstructionDialog from "../../components/AddInstructionDialog";
import { addInstructionDialogAtom } from "../../atoms/dialogsAtom";
import { Fab, IconButton, TableCell, TableRow } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BasicTable from "../../components/BasicTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditableTextField from "../../components/EditableTextField";
import StyledTableCell from "../../components/StyledTableCell";

export default function InstructionsPage() {
  const location = useLocation();
  const [instructions, setInstructions] = useRecoilState(instructionsAtom);
  const [open, setOpen] = useRecoilState(addInstructionDialogAtom);

  const _getInstructions = () => {
    getInstructions(1, 9999)
      .then((data) => {
        console.log("Fetched instructions", data);
        setInstructions(data);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    _getInstructions();
  }, []);

  return (
    <div>
      <TopAppBar />
      <AddInstructionDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleAdd={(title: string, content: string) => {
          postInstruction(title, content)
            .then(() => {
              _getInstructions();
              setOpen(false);
            })
            .catch((error) => console.error(error));
        }}
      />
      <BasicTable
        columns={[
          { id: "id", content: "Id", width: "5%" },
          { id: "title", content: "Title", width: "45%" },
          { id: "content", content: "Content", width: "45%" },
          { id: "actions", content: "Actions", width: "5%" },
        ]}
      >
        {instructions?.data?.map((instruction: any) => (
          <TableRow key={instruction.id} hover>
            <StyledTableCell>{instruction.id}</StyledTableCell>
            <StyledTableCell>
              <EditableTextField
                value={instruction.attributes.title}
                onChange={(newTitle: string) => {
                  updateInstruction(
                    instruction.id,
                    newTitle,
                    instruction.attributes.content
                  ).then(() => {
                    _getInstructions();
                  });
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <EditableTextField
                value={instruction.attributes.content}
                onChange={(newContent: string) => {
                  updateInstruction(
                    instruction.id,
                    instruction.attributes.title,
                    newContent
                  ).then(() => {
                    _getInstructions();
                  });
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  console.log("Delete instruction", instruction.id);
                  deleteInstruction(instruction.id).then(() => {
                    _getInstructions();
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </StyledTableCell>
          </TableRow>
        ))}
      </BasicTable>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
