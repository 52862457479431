import { atom } from "recoil";

export const addBasicInfoDialogAtom = atom<boolean>({
  key: "addBasicInfoDialogAtom",
  default: false,
});

export const addColumnDialogAtom = atom<boolean>({
  key: "addColumnDialogAtom",
  default: false,
});

export const addInstructionDialogAtom = atom<boolean>({
  key: "addInstructionDialogAtom",
  default: false,
});

export const addComponentDialogAtom = atom<boolean>({
  key: "addComponentDialogAtom",
  default: false,
});

export const addComponentBasicInfoDialogAtom = atom<boolean>({
  key: "addComponentBasicInfoDialogAtom",
  default: false,
});

export const addSampleBasicInfoDialogAtom = atom<boolean>({
  key: "addSampleBasicInfoDialogAtom",
  default: false,
});

export const addSampleDatasetDialogAtom = atom<boolean>({
  key: "addSampleDatasetDialogAtom",
  default: false,
});

export const addSampleComponentDialogAtom = atom<boolean>({
  key: "addSampleComponentDialogAtom",
  default: false,
});

export const addSampleDialogAtom = atom<boolean>({
  key: "addSampleDialogAtom",
  default: false,
});

export const addDatasetDialogAtom = atom<boolean>({
  key: "addDatasetDialogAtom",
  default: false,
});
