import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import SelectDataset from "./SelectDataset";
import AddIcon from "@mui/icons-material/Add";
import { useRecoilState } from "recoil";
import {
  addDatasetDialogAtom,
  addInstructionDialogAtom,
} from "../atoms/dialogsAtom";
import Navigation from "./Navigation";
import SelectInstruction from "./SelectInstruction";

export default function TopAppBar() {
  const [open, setOpen] = useRecoilState(addDatasetDialogAtom);
  const [openInstruction, setOpenInstruction] = useRecoilState(
    addInstructionDialogAtom
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          {/* <Typography
            variant="h6"
            component="div"
            sx={{
              mr: 4,
            }}
          >
            Dataset Captioining Tool
          </Typography> */}

          <SelectDataset />
          <Box sx={{ width: 12 }}></Box>

          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => setOpen(true)}
          >
            Add
          </Button>
          <Box sx={{ width: 12 }}></Box>

          <SelectInstruction />

          <Box sx={{ width: 12 }}></Box>
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={() => setOpenInstruction(true)}
          >
            Add
          </Button>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Navigation />
        </Toolbar>
      </AppBar>
    </Box>
  );
}
