import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

interface BasicSelectProps {
  inputLabel: string;
  currnetValue: string;
  values: { id: string; title: string }[];
  handleChange: (event: SelectChangeEvent) => void;
}

export default function BasicSelect({
  values,
  handleChange,
  inputLabel,
  currnetValue,
}: BasicSelectProps) {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{inputLabel}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currnetValue}
          label="Column"
          onChange={handleChange}
        >
          {values.map((value) => (
            <MenuItem value={value.id} key={value.id}>
              {value.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
