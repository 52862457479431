import axios from "axios";

const OPENAI_API_KEY =
  "sk-proj-UrH8whMLOL0Wf9WLjDq5T3BlbkFJIEKWHyYayHJyHhbMf5Ia";

export const getCaptionForImage = async (
  url: string,
  instruction: string
): Promise<string> => {
  // console.log("instruction", instruction);
  // return "caption";
  console.log("url", url);
  try {
    const response = await axios.post(
      "https://api.openai.com/v1/chat/completions",
      {
        model: "gpt-4o",
        messages: [
          {
            role: "system",
            content: instruction,
          },
          {
            role: "user",
            content: [
              {
                type: "image_url",
                image_url: {
                  url: url,
                },
              },
            ],
          },
        ],
        temperature: 1,
        max_tokens: 256,
        top_p: 1,
        frequency_penalty: 0,
        presence_penalty: 0,
      },
      {
        headers: {
          Authorization: `Bearer ${OPENAI_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );
    console.log("response", response.data.choices[0].message.content);
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error("Error fetching caption:", error);
    throw new Error("Failed to fetch caption");
  }
};
