import axios, { AxiosError, AxiosResponse } from "axios";
import handleAxiosError from "./axiosError";
import { BASE_URL } from "../utils/constants";

// Utility to build common API call parameters
const buildAPIParams = (
  page: number,
  pageSize: number,
  description?: string
) => ({
  pagination: { page, pageSize },
  filter: description ? { content: { $regex: description } } : undefined,
  populate: {
    basic_infos: {
      populate: "*", // This populates all fields within `basic_infos` one level deep
    },
  },
  sort: ["id"],
});

export async function getComponents(
  page = 1,
  pageSize = 10,
  description?: string
) {
  try {
    const params = buildAPIParams(page, pageSize, description);

    const res = await axios.get(BASE_URL + "/components", {
      params,
    });
    return res.data;
  } catch (error) {
    console.error("Error fetching components:", error);
    return handleAxiosError(error);
  }
}

export async function postComponent(
  description: string,
  basic_infos: string[] = []
) {
  try {
    const res = await axios.post(BASE_URL + "/components", {
      data: { description, basic_infos },
    });
    return res.data;
  } catch (error) {
    console.error("Error posting column:", error);
    return handleAxiosError(error);
  }
}

export async function deleteComponent(id: string) {
  try {
    const res = await axios.delete(BASE_URL + `/components/${id}`);
    return res.data;
  } catch (error) {
    console.error("Error deleting component:", error);
    return handleAxiosError(error);
  }
}

export async function updateComponent(
  id: string,
  description?: string,
  basic_infos?: string[]
) {
  try {
    const res = await axios.put(BASE_URL + `/components/${id}`, {
      data: { description, basic_infos },
    });
    return res.data;
  } catch (error) {
    console.error("Error updating component:", error);
    return handleAxiosError(error);
  }
}
