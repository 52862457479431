import { Add } from "@mui/icons-material";
import { Stack, Chip, IconButton, Box, colors } from "@mui/material";
import { updateSample } from "../../../services/samplesService";

interface DatasetsChipsProps {
  sample: EntityWrapper<Sample>;
  _getSamples: () => void;
  setOpenAddDataset: (open: boolean) => void;
  setActiveSample: (sample: EntityWrapper<Sample>) => void;
}

export default function DatasetsChips({
  sample,
  _getSamples,
  setOpenAddDataset,
  setActiveSample,
}: DatasetsChipsProps) {
  return (
    <Stack direction={"row"} spacing={1} flexWrap={"wrap"} gap={1}>
      {sample.attributes.datasets.data?.map((ds) => (
        <Chip
          key={ds.id}
          label={
            <Box sx={{ display: "flex", gap: 0.5 }}>
              <Box> {ds.attributes.name}</Box>
            </Box>
          }
          onDelete={() => {
            updateSample(
              sample.id.toString(),
              undefined,
              undefined,
              undefined,
              undefined,
              undefined,
              sample.attributes.datasets.data
                .filter((b) => b.id !== ds.id)
                .map((b) => b.id.toString())
            ).then(() => {
              _getSamples();
            });
          }}
        />
      ))}
      <IconButton
        aria-label="add"
        size="small"
        onClick={() => {
          setOpenAddDataset(true);
          setActiveSample(sample);
        }}
      >
        <Add />
      </IconButton>
    </Stack>
  );
}
