import { TableCell, TableCellProps, useTheme } from "@mui/material";

interface StyledTableCellProps extends TableCellProps {
  maxWidth?: number;
  width?: number;
  backgroundColor?: string;
}

const StyledTableCell: React.FC<StyledTableCellProps> = ({
  maxWidth,
  width,
  backgroundColor,
  ...props
}) => {
  const theme = useTheme();
  const customStyle = {
    border: "1px solid",
    borderColor: theme.palette.divider,
    padding: theme.spacing(1),
    //align items to the top of the cell
    verticalAlign: "top",
    // apply maxWidth if provided
    maxWidth: maxWidth ? `${maxWidth}px` : "none",
    width: width ? `${width}px` : "auto",
    backgroundColor: backgroundColor ? backgroundColor : undefined,
  };

  return <TableCell {...props} sx={{ ...customStyle, ...props.sx }} />;
};

export default StyledTableCell;
