import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Stack,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import BaseDialog from "./BaseDialog";
import * as React from "react";
import { getBasicInfos, postBasicInfo } from "../services/basicInfosService";

interface AddSampleBasicInfoDialogProps {
  handleClose: () => void;
  handleAdd: (basicInfos: EntityWrapper<BasicInfo>[]) => void;
  sample?: EntityWrapper<Sample>;
  open: boolean;
}

export default function AddSampleBasicInfoDialog({
  handleClose,
  handleAdd,
  open,
}: AddSampleBasicInfoDialogProps) {
  const [basicInfos, setBasicInfos] =
    React.useState<ResponseWrapper<EntityWrapper<BasicInfo>[]>>();
  const [basicInfo, setBasicInfo] = React.useState("");
  const [selectedBasicInfos, setSelectedBasicInfos] = React.useState<
    EntityWrapper<BasicInfo>[]
  >([]);
  const _getBasicInfos = () => {
    getBasicInfos(1, 20, "", basicInfo)
      .then((data) => {
        setBasicInfos(data);
      })
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    _getBasicInfos();
  }, [basicInfo]);

  return (
    <BaseDialog
      handleClose={handleClose}
      open={open}
      title="Add Basic Infos to Sample"
      positiveTitle="Add"
      handlePositive={() => {
        handleAdd(selectedBasicInfos);
      }}
    >
      <Box sx={{ minWidth: "512px", mt: 1 }}>
        <Stack direction={"row"} spacing={1}>
          <Autocomplete
            onChange={(e, value) => {
              if (value) {
                if (
                  value.id &&
                  selectedBasicInfos.filter(
                    (e) => e.id.toString() === value.id.toString()
                  ).length === 0
                ) {
                  setSelectedBasicInfos([...selectedBasicInfos, value]);
                }
              }
            }}
            fullWidth
            // disablePortal
            id="combo-box-demo"
            options={basicInfos?.data || []}
            getOptionLabel={(option) => option.attributes.content}
            renderOption={(props, option) => {
              const value = option.attributes.content;
              const column = option.attributes.column?.data.attributes.title;
              return (
                <li {...props}>
                  <Box sx={{ display: "flex", gap: 0.5 }}>
                    <Box sx={{ color: colors.blue[200] }}>{column}</Box>
                    <Box>|</Box>
                    <Box> {value}</Box>
                  </Box>
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  value={basicInfo}
                  onChange={(e) => setBasicInfo(basicInfo)}
                  {...params}
                  label="Basic Infos"
                  variant="outlined"
                  fullWidth
                />
              );
            }}
          />
          {/* <Button variant="outlined">
            <Typography variant="h6">+</Typography>
          </Button> */}
        </Stack>

        <Stack
          direction={"row"}
          spacing={1}
          flexWrap={"wrap"}
          gap={1}
          sx={{ mt: 2 }}
        >
          {selectedBasicInfos.map((basicInfo) => (
            <Chip
              label={
                <Box sx={{ display: "flex", gap: 0.5 }}>
                  <Box sx={{ color: colors.blue[200] }}>
                    {basicInfo.attributes.column?.data.attributes.title}
                  </Box>
                  <Box>|</Box>
                  <Box> {basicInfo.attributes.content}</Box>
                </Box>
              }
              key={basicInfo.id}
              onDelete={() => {
                setSelectedBasicInfos(
                  selectedBasicInfos.filter((e) => e.id !== basicInfo.id)
                );
              }}
            />
          ))}
        </Stack>
      </Box>
    </BaseDialog>
  );
}
