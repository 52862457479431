import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StyledTableCell from "./StyledTableCell";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows: any = [
  //   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  //   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  //   createData("Eclair", 262, 16.0, 24, 6.0),
  //   createData("Cupcake", 305, 3.7, 67, 4.3),
  //   createData("Gingerbread", 356, 16.0, 49, 3.9),
];

interface BasicTableProps {
  columns: {
    id: string;
    content: string;
    width?: string;
  }[];
  minWidth?: string;
  children?: React.ReactNode;
  heightSafe?: number;
}

export default function BasicTable({
  columns,
  children,
  minWidth,
  heightSafe,
}: BasicTableProps) {
  return (
    <TableContainer
      component={Paper}
      sx={{ height: `calc(100vh - ${heightSafe}px)` }}
    >
      <Table stickyHeader sx={{ minWidth: minWidth }} aria-label="simple table">
        {/* <colgroup>
          {columns.map((column) => (
            <col key={column.id} width={column.width} />
          ))}
        </colgroup> */}
        <TableHead>
          <TableRow selected>
            {columns.map((column) => (
              <StyledTableCell key={column.id}>
                {column.content}
              </StyledTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>{children}</TableBody>
      </Table>
    </TableContainer>
  );
}
