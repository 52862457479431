import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import BaseDialog from "./BaseDialog";
import * as React from "react";
import { postBasicInfo } from "../services/basicInfosService";
import { useRecoilState, useSetRecoilState } from "recoil";
import { addBasicInfoDialogAtom } from "../atoms/dialogsAtom";
import { datasetsAtom } from "../atoms/datasetsAtom";

interface AddColumnDialogProps {
  handleClose: () => void;
  handleAdd: (content: string) => void;
  open: boolean;
}

export default function PrepareDownloadDialog({
  handleClose,
  handleAdd,
  open,
}: AddColumnDialogProps) {
  const [value, setValue] = React.useState<string>("");
  const [datasets, setDatasets] = useRecoilState(datasetsAtom);
  const [selectedDataset, setSelectedDataset] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedDataset(event.target.value);
  };

  if (!datasets?.data?.length) {
    return null;
  }

  return (
    <BaseDialog
      handleClose={handleClose}
      open={open}
      title="Prepare Download"
      positiveTitle="Prepare"
      handlePositive={() => {
        handleAdd(selectedDataset);
      }}
    >
      <Box sx={{ minWidth: "512px", mt: 1 }}>
        <FormControl fullWidth sx={{ height: 60 }}>
          <InputLabel id="demo-simple-select-label">Dataset</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedDataset}
            label="Dataset"
            onChange={handleChange}
            size="small"
            sx={{ height: 56 }}
          >
            {datasets.data.map((dataset) => (
              <MenuItem value={dataset.id} key={dataset.id}>
                {dataset.attributes.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </BaseDialog>
  );
}
