import React from "react";
import { Chip, Stack } from "@mui/material";

interface ChipsDatasetsDisplayProps {
  items: EntityWrapper<Dataset>[];
  setItems: (items: EntityWrapper<Dataset>[]) => void;
}
const ChipsDatasesDisplay = ({
  items,
  setItems,
}: ChipsDatasetsDisplayProps) => {
  return (
    <Stack
      direction={"row"}
      spacing={1}
      flexWrap={"wrap"}
      gap={1}
      sx={{ mt: 2 }}
    >
      {items.map((item) => (
        <Chip
          label={item.attributes.name}
          key={item.id}
          onDelete={() => setItems(items.filter((e) => e.id !== item.id))}
        />
      ))}
    </Stack>
  );
};

export default ChipsDatasesDisplay;
