import axios from "axios";
import { BASE_URL } from "../utils/constants";
import handleAxiosError from "./axiosError";
import { datasets } from "cohere-ai/api";

const buildAPIParams = (
  page: number,
  pageSize: number,
  datasets?: string[]
) => ({
  pagination: { page, pageSize },
  populate: {
    components_repeat: { populate: "*" },
    photo: {
      populate: "*",
    },
    datasets: {
      populate: "*",
    },
    basic_infos: {
      populate: "*", // This populates all fields within `basic_infos` one level deep
    },
    components: {
      populate: {
        basic_infos: {
          populate: "*", // This populates all fields within `basic_infos` one level deep
        },
      }, // This populates all fields within `components` one level deep
    },
  },
  sort: ["id:desc"],
  filters: datasets ? { datasets: { id: { $in: datasets } } } : undefined,
});

export async function getSamples(page = 1, pageSize = 10, datasets?: string[]) {
  try {
    const params = buildAPIParams(page, pageSize, datasets);
    console.log("params", params);
    const res = await axios.get(BASE_URL + "/samples", {
      params,
    });
    console.log("params", res);
    return res.data;
  } catch (error) {
    console.error("Error fetching samples:", error);
    return handleAxiosError(error);
  }
}

export async function postSample(
  description: string,
  basic_infos: string[] = [],
  components: string[] = [],
  photo: string,
  components_repeat: ComponentRepeat[] = [],
  datasets: string[] = []
) {
  try {
    const res = await axios.post(BASE_URL + "/samples", {
      data: {
        photo,
        description,
        basic_infos,
        components,
        components_repeat,
        datasets,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error posting sample:", error);
    return handleAxiosError(error);
  }
}

export async function deleteSample(id: string) {
  try {
    const res = await axios.delete(BASE_URL + `/samples/${id}`);
    return res.data;
  } catch (error) {
    console.error("Error deleting sample:", error);
    return handleAxiosError(error);
  }
}

export async function updateSample(
  id: string,
  photo?: string,
  description?: string,
  basic_infos?: string[],
  components?: string[],
  components_repeat?: ComponentRepeat[],
  datasets?: string[],
  prompt?: string
) {
  try {
    const res = await axios.put(BASE_URL + `/samples/${id}`, {
      data: {
        photo,
        description,
        basic_infos,
        components,
        components_repeat,
        datasets: datasets,
        prompt,
      },
    });
    return res.data;
  } catch (error) {
    console.error("Error updating sample:", error);
    return handleAxiosError(error);
  }
}
