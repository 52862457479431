import axios, { AxiosError } from "axios";

export default function handleAxiosError<T>(
  error: unknown
): ResponseWrapper<T> {
  let errorMessage = "An unexpected error occurred.";
  if (axios.isAxiosError(error)) {
    const serverError = error as AxiosError<ResponseWrapper<T>>;
    if (serverError && serverError.response) {
      console.error(
        "Server responded with an error:",
        serverError.response.data
      );
      errorMessage =
        serverError.response.data?.message || "A server error occurred.";
    } else {
      console.error("Error message:", error.message);
      errorMessage =
        error.message || "An error occurred while setting up the request.";
    }
  } else {
    console.error("Unexpected error:", error);
  }

  // Return a meaningful error message inside the ResponseWrapper format
  return {
    data: undefined, // Assuming 'undefined' is acceptable when there's an error
    message: errorMessage,
  };
}
