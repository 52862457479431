import axios, { AxiosError, AxiosResponse } from "axios";
import handleAxiosError from "./axiosError";
import { BASE_URL } from "../utils/constants";

// Utility to build common API call parameters
const buildAPIParams = (page: number, pageSize: number, name?: string) => ({
  pagination: { page, pageSize },
  populate: "*",
  sort: ["name"],
  filter: name ? { content: { $regex: name } } : undefined,
});

export async function getDataset(page = 1, pageSize = 100, name?: string) {
  try {
    const params = buildAPIParams(page, pageSize, name);

    const res = await axios.get(BASE_URL + "/datasets", {
      params,
    });
    console.log("datasets", res.data);
    return res.data;
  } catch (error) {
    console.error("Error fetching dataset:", error);
    return handleAxiosError(error);
  }
}

export async function postDataset(name: string) {
  try {
    const res = await axios.post(BASE_URL + "/datasets", {
      data: { name },
    });
    return res.data;
  } catch (error) {
    console.error("Error posting dataset:", error);
    return handleAxiosError(error);
  }
}

export async function deleteDataset(id: string) {
  try {
    const res = await axios.delete(BASE_URL + `/datasets/${id}`);
    return res.data;
  } catch (error) {
    console.error("Error deleting dataset:", error);
    return handleAxiosError(error);
  }
}

export async function updateDataset(id: string, name?: string) {
  try {
    const res = await axios.put(BASE_URL + `/datasets/${id}`, {
      data: { name },
    });
    return res.data;
  } catch (error) {
    console.error("Error updating dataset:", error);
    return handleAxiosError(error);
  }
}
