import axios from "axios";
import { BASE_URL } from "../utils/constants";
import handleAxiosError from "./axiosError";

const buildAPIParams = (page: number, pageSize: number) => ({
  pagination: { page, pageSize },
  populate: "*",
  sort: ["id:desc"],
});

export async function getHistories(page = 1, pageSize = 10) {
  try {
    const params = buildAPIParams(page, pageSize);
    const res = await axios.get(BASE_URL + "/histories", {
      params,
    });
    console.log("histories", res);
    return res.data;
  } catch (error) {
    console.error("Error fetching histories:", error);
    return handleAxiosError(error);
  }
}

const buildDownloadParams = (datasetID: string) => ({
  dataset_id: datasetID,
});

export async function getDownload(datasetID: string) {
  try {
    const params = buildDownloadParams(datasetID);
    const res = await axios.get(BASE_URL + "/download", {
      params,
    });
    console.log("histories", res);
    return res.data;
  } catch (error) {
    console.error("Error fetching histories:", error);
    return handleAxiosError(error);
  }
}
