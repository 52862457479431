import { atom } from "recoil";

export const datasetsAtom = atom<ResponseWrapper<
  EntityWrapper<Dataset>[]
> | null>({
  key: "datasetsAtom",
  default: null,
});

export const currentDatasetAtom = atom<string>({
  key: "currentDatasetAtom",
  default: "",
});
