import { useLocation } from "react-router-dom";
import TopAppBar from "../../components/TopAppBar";
import { columnsAtom } from "../../atoms/columsAtom";
import { useRecoilState } from "recoil";

import { useEffect, useState } from "react";
import AddColumnDialog from "../../components/AddColumnDialog";
import { addColumnDialogAtom } from "../../atoms/dialogsAtom";
import {
  Backdrop,
  CircularProgress,
  Fab,
  IconButton,
  Link,
  TableCell,
  TableRow,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BasicTable from "../../components/BasicTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditableTextField from "../../components/EditableTextField";
import StyledTableCell from "../../components/StyledTableCell";
import { historiesAtom } from "../../atoms/historiesAtom";
import { getDownload, getHistories } from "../../services/historyService";
import { formatDistanceToNow, set } from "date-fns";
import PrepareDownloadDialog from "../../components/PrepareDownloadDialog";

export default function DwonloadsPage() {
  const location = useLocation();
  const [histories, setHistories] = useRecoilState(historiesAtom);
  const [open, setOpen] = useRecoilState(addColumnDialogAtom);
  const [loading, setLoading] = useState(false);

  const _getHistories = () => {
    getHistories(1, 9999)
      .then((data) => {
        console.log("Fetched h", data);
        setHistories(data);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    _getHistories();
  }, []);

  return (
    <div>
      <TopAppBar />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <PrepareDownloadDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleAdd={(content: string) => {
          setLoading(true);
          setOpen(false);
          getDownload(content)
            .then(() => {
              _getHistories();

              setLoading(false);
            })
            .catch((error) => console.error(error));
        }}
      />
      <BasicTable
        columns={[
          { id: "id", content: "Id" },
          { id: "url", content: "URL" },
          { id: "dataset", content: "Dataset" },
          { id: "createdat", content: "Created At" },
          { id: "actions", content: "Actions" },
        ]}
      >
        {histories?.data?.map((history: EntityWrapper<History>) => {
          const date = new Date(history.attributes.createdAt ?? "");
          const formattedDate = formatDistanceToNow(date, { addSuffix: true });

          return (
            <TableRow key={history.id} hover>
              <StyledTableCell>{history.id}</StyledTableCell>
              <StyledTableCell>
                <Link href={history.attributes.download_url}>
                  {history.attributes.download_url}
                </Link>
              </StyledTableCell>
              <StyledTableCell>
                {history.attributes.dataset.data?.attributes.name}
              </StyledTableCell>
              <StyledTableCell>{formattedDate}</StyledTableCell>
              <StyledTableCell>
                <IconButton
                  aria-label="delete"
                  size="small"
                  onClick={() => {
                    console.log("Delete history", history.id);
                    // deleteColumn(history.id).then(() => {
                    //   _getHistories();
                    // });
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          );
        })}
      </BasicTable>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
