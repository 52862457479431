import { Box, Card, Typography, useTheme, Button, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import uploadImageToStrapi from "../../services/uploadImageToStrapi";

interface ImageSelectorProps {
  setImagesList: (images: string[]) => void;
  setFilesList: (files: File[]) => void;
}

export default function ImageSelector({
  setFilesList,
}: // images,
// setImages,
// files,
// setFiles,

ImageSelectorProps) {
  const theme = useTheme();
  const [images, setImages] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [dragOver, setDragOver] = useState(false);

  useEffect(() => {
    setFilesList(files);
  }, [files]);

  // Handle file selection
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files
      ? Array.from(event.target.files)
      : [];
    const newImages: string[] = [];
    selectedFiles.forEach((file) => {
      if (file.type.startsWith("image")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImages((prevImages) => [...prevImages, reader.result as string]);
        };
        reader.readAsDataURL(file);
      }
    });
    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    event.target.value = ""; // Reset the input after file is selected
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false);
    const droppedFiles = Array.from(event.dataTransfer.files);
    droppedFiles.forEach((file) => {
      if (file.type.startsWith("image")) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setImages((prevImages) => [...prevImages, reader.result as string]);
        };
        reader.readAsDataURL(file);
      }
    });
    setFiles((prevFiles) => [...prevFiles, ...droppedFiles]);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragOver(false);
  };

  const handleUploadAll = () => {
    files.forEach((file) => {
      uploadImageToStrapi(file).then((res) => {
        console.log(res);
      });
    });
  };

  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        background: dragOver
          ? theme.palette.action.hover
          : theme.palette.divider,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        padding: 2,
      }}
      variant="outlined"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onClick={() => fileInputRef.current?.click()}
    >
      <Box
        sx={{
          cursor: "pointer",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {images.length > 0 ? (
          <Grid container spacing={2}>
            {images.map((image, index) => (
              <Grid item xs={4} key={index}>
                <img
                  src={image}
                  alt={`Uploaded ${index}`}
                  style={{ maxWidth: "100%", maxHeight: "100px" }}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography variant="h6" align="center">
            {dragOver ? "Drop images here" : "Choose or drag images"}
          </Typography>
        )}
        <input
          type="file"
          accept="image/*"
          multiple
          style={{ display: "none" }}
          onChange={handleImageChange}
          ref={fileInputRef}
        />
      </Box>
      {files.length > 0 && (
        <Button
          variant="contained"
          color="primary"
          onClick={handleUploadAll}
          sx={{ mt: 2 }}
        >
          Upload All Images
        </Button>
      )}
    </Card>
  );
}
