import { useState } from "react";
import DialogLayout from "./DialogLayout";
import ComponentSelector from "./ComponentSelector";
import {
  useBasicInfos,
  useComponents,
  useDatasets,
  useDescription,
  useImage,
} from "./useDataHooks";
import DescriptionField from "./DescriptionField";
import BasicInfoSelector from "./BasicInfoSelector";
import { Grid } from "@mui/material";
import ImageSelector from "./ImageSelector";
import DatasetSelector from "./DatasetSelector";
import uploadImageToStrapi from "../../services/uploadImageToStrapi";
import { postSample } from "../../services/samplesService";

interface AddSampleDialogProps {
  handleClose: () => void;
  handleAdd: () => void;
  open: boolean;
}

export default function AddSampleDialog({
  handleClose,
  handleAdd,
  open,
}: AddSampleDialogProps) {
  const { description, setDescription } = useDescription();

  const {
    basicInfos,
    basicInfo,
    setBasicInfo,
    selectedBasicInfos,
    setSelectedBasicInfos,
  } = useBasicInfos();

  const {
    datasets,
    dataset,
    setDataset,
    selectedDatasets,
    setSelectedDatasets,
  } = useDatasets();

  const {
    components,
    component,
    setComponent,
    selectedComponents,
    setSelectedComponents,
  } = useComponents();

  const [componentRepeat, setComponentRepeat] = useState<ComponentRepeat[]>([]);

  const { images, setImages, files, setFiles } = useImage();

  return (
    <DialogLayout
      open={open}
      handleClose={handleClose}
      handleAdd={() => {
        console.log("Adding sample");
        console.log(files);
        console.log(selectedDatasets);
        if (selectedDatasets.length > 0 && files.length > 0) {
          files.forEach((file) => {
            console.log(file);
            uploadImageToStrapi(file).then((data) => {
              postSample(
                description ?? "",
                selectedBasicInfos.map((item) => item.id.toString()) ??
                  undefined,
                selectedComponents.map((item) => item.id.toString()) ??
                  undefined,
                data[0].id,
                componentRepeat ?? undefined,
                selectedDatasets.map((item) => item.id.toString())
              ).then(handleAdd);
            });
          });
        }
      }}
    >
      <Grid container spacing={2} sx={{ p: 4, height: "100%", width: "100%" }}>
        <Grid item xs={9}>
          <ImageSelector setImagesList={setImages} setFilesList={setFiles} />
        </Grid>
        <Grid
          item
          xs={3}
          sx={{ display: "flex", flexDirection: "column", gap: 4 }}
        >
          {/* <DescriptionField
            description={description}
            setDescription={setDescription}
          /> */}
          {/* <ComponentSelector
            label="Components"
            items={components}
            selectedItem={component}
            setSelectedItem={setComponent}
            selectedItems={selectedComponents}
            setSelectedItems={setSelectedComponents}
            componentRepeat={componentRepeat}
            setComponentRepeat={setComponentRepeat}
          /> */}

          {/* <BasicInfoSelector
            label="Basic Infos"
            items={basicInfos}
            selectedItem={basicInfo}
            setSelectedItem={setComponent}
            selectedItems={selectedBasicInfos}
            setSelectedItems={setSelectedBasicInfos}
          /> */}

          <DatasetSelector
            label="Datasets"
            items={datasets}
            selectedItem={dataset}
            setSelectedItem={setDataset}
            selectedItems={selectedDatasets}
            setSelectedItems={setSelectedDatasets}
          />
        </Grid>
      </Grid>
    </DialogLayout>
  );
}
