import React from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import ChipsBasicInfosDisplay from "./ChipsBasicInfosDisplay";
import ChipsDatasesDisplay from "./ChipsDatasetsDisplay";

interface DatasetSelectorProps {
  label: string;
  items: ResponseWrapper<EntityWrapper<Dataset>[]> | null;
  selectedItem: string;
  setSelectedItem: (item: string) => void;
  selectedItems: EntityWrapper<Dataset>[];
  setSelectedItems: (items: EntityWrapper<Dataset>[]) => void;
}

const DatasetSelector = ({
  label,
  items,
  selectedItem,
  setSelectedItem,
  selectedItems,
  setSelectedItems,
}: DatasetSelectorProps) => {
  return (
    <Box>
      <Autocomplete
        fullWidth
        options={items?.data || []}
        getOptionLabel={(option) => option.attributes.name}
        renderOption={(props, option) => (
          <li {...props}>
            <Typography>{option.attributes.name}</Typography>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            value={selectedItem}
            onChange={(e) => setSelectedItem(e.target.value)}
          />
        )}
        onChange={(event, value) => {
          if (value && !selectedItems.some((item) => item.id === value.id)) {
            setSelectedItems([...selectedItems, value]);
          }
        }}
      />

      <ChipsDatasesDisplay items={selectedItems} setItems={setSelectedItems} />
    </Box>
  );
};

export default DatasetSelector;
