import axios, { AxiosError, AxiosResponse } from "axios";
import handleAxiosError from "./axiosError";
import { BASE_URL } from "../utils/constants";

// Utility to build common API call parameters
const buildAPIParams = (page: number, pageSize: number) => ({
  pagination: { page, pageSize },

  populate: "*",
  sort: ["title"],
});

export async function getColumns(page = 1, pageSize = 10) {
  try {
    const params = buildAPIParams(page, pageSize);

    const res = await axios.get(BASE_URL + "/columns", {
      params,
    });
    return res.data;
  } catch (error) {
    console.error("Error fetching columns:", error);
    return handleAxiosError(error);
  }
}

export async function postColumn(title: string) {
  try {
    const res = await axios.post(BASE_URL + "/columns", {
      data: { title },
    });
    return res.data;
  } catch (error) {
    console.error("Error posting column:", error);
    return handleAxiosError(error);
  }
}

export async function deleteColumn(id: string) {
  try {
    const res = await axios.delete(BASE_URL + `/columns/${id}`);
    return res.data;
  } catch (error) {
    console.error("Error deleting column:", error);
    return handleAxiosError(error);
  }
}

export async function updateColumn(id: string, newTitle?: string) {
  try {
    const res = await axios.put(BASE_URL + `/columns/${id}`, {
      data: { title: newTitle },
    });
    return res.data;
  } catch (error) {
    console.error("Error updating column:", error);
    return handleAxiosError(error);
  }
}
