import { useLocation } from "react-router-dom";
import TopAppBar from "../../components/TopAppBar";
import { columnsAtom } from "../../atoms/columsAtom";
import { useRecoilState } from "recoil";
import {
  deleteColumn,
  getColumns,
  postColumn,
  updateColumn,
} from "../../services/columnsService";
import { useEffect } from "react";
import AddColumnDialog from "../../components/AddColumnDialog";
import { addColumnDialogAtom } from "../../atoms/dialogsAtom";
import { Fab, IconButton, TableCell, TableRow } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import BasicTable from "../../components/BasicTable";
import DeleteIcon from "@mui/icons-material/Delete";
import EditableTextField from "../../components/EditableTextField";
import StyledTableCell from "../../components/StyledTableCell";

export default function ColumnsPage() {
  const location = useLocation();
  const [columns, setColumns] = useRecoilState(columnsAtom);
  const [open, setOpen] = useRecoilState(addColumnDialogAtom);

  const _getColumns = () => {
    getColumns(1, 9999)
      .then((data) => {
        console.log("Fetched columns", data);
        setColumns(data);
      })
      .catch((error) => console.error(error));
  };
  useEffect(() => {
    _getColumns();
  }, []);

  return (
    <div>
      <TopAppBar />
      <AddColumnDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleAdd={(content: string) => {
          postColumn(content)
            .then(() => {
              _getColumns();
              setOpen(false);
            })
            .catch((error) => console.error(error));
        }}
      />
      <BasicTable
        columns={[
          { id: "id", content: "Id", width: "5%" },
          { id: "title", content: "Title", width: "90%" },
          { id: "actions", content: "Actions", width: "5%" },
        ]}
      >
        {columns?.data?.map((column: any) => (
          <TableRow key={column.id} hover>
            <StyledTableCell>{column.id}</StyledTableCell>
            <StyledTableCell>
              <EditableTextField
                value={column.attributes.title}
                onChange={(newTitle: string) => {
                  updateColumn(column.id, newTitle).then(() => {
                    _getColumns();
                  });
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  console.log("Delete column", column.id);
                  deleteColumn(column.id).then(() => {
                    _getColumns();
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </StyledTableCell>
          </TableRow>
        ))}
      </BasicTable>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
