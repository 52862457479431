import axios from "axios";
import { BASE_URL } from "../utils/constants";
import handleAxiosError from "./axiosError";

// Handle file upload to Strapi
export default async function uploadImageToStrapi(file: File | null) {
  if (file) {
    const formData = new FormData();
    formData.append("files", file);

    try {
      const response = await axios.post(BASE_URL + "/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("File uploaded successfully:", response.data);
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error);
      return handleAxiosError(error);
    }
  }
}
