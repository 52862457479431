import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  getInstructions,
  postInstruction,
} from "../services/instructionsService";
import { useRecoilState } from "recoil";
import { addInstructionDialogAtom } from "../atoms/dialogsAtom";
import AddInstructionDialog from "./AddInstructionDialog";
import {
  currentInstructionAtom,
  instructionsAtom,
} from "../atoms/instructionsAtom";

export default function SelectInstruction() {
  const [instructions, setInstructions] = useRecoilState(instructionsAtom);
  const [open, setOpen] = useRecoilState(addInstructionDialogAtom);
  const [currentInstruction, setCurrentInstruction] = useRecoilState(
    currentInstructionAtom
  );

  const _getInstructions = async () => {
    try {
      const response = await getInstructions();
      setInstructions(response);
      if (currentInstruction != "") {
        return;
      }
      setCurrentInstruction(response.data[0].id.toString());
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    _getInstructions();
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setCurrentInstruction(event.target.value);
  };

  if (!instructions?.data?.length) {
    return null;
  }
  return (
    <Box sx={{ minWidth: 300 }}>
      <AddInstructionDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleAdd={(title: string, content: string) => {
          postInstruction(title, content)
            .then(() => {
              _getInstructions();
              setOpen(false);
            })
            .catch((error) => console.error(error));
        }}
      />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Instruction</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentInstruction}
          label="Instruction"
          onChange={handleChange}
          size="small"
        >
          {instructions.data.map((instruction) => (
            <MenuItem value={instruction.id} key={instruction.id}>
              {instruction.attributes.title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
