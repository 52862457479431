import { Edit } from "@mui/icons-material";
import { Box, IconButton, TextField } from "@mui/material";
import React from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface EditableTextFieldProps {
  value: string;
  onChange: (value: string) => void;
}

export default function EditableTextField({
  value,
  onChange,
}: EditableTextFieldProps) {
  const [editMode, setEditMode] = React.useState<boolean>(false);
  const [editedVlaue, setEditedValue] = React.useState<string>(value);
  return (
    <Box sx={{ display: "flex", gap: 3, width: "100%" }}>
      <Box sx={{ display: editMode ? "none" : "flex" }}>{value}</Box>
      <Box sx={{ flexGrow: 1, display: editMode ? "flex" : "none" }}>
        <TextField
          id="outlined-basic"
          label={value}
          variant="outlined"
          multiline
          fullWidth
          value={editedVlaue}
          onChange={(e) => setEditedValue(e.target.value)}
        />
      </Box>
      <Box
        sx={{
          display: editMode ? "none" : "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          aria-label="delete"
          size="small"
          onClick={() => {
            setEditMode(true);
          }}
        >
          <Edit fontSize="small" />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: editMode ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          aria-label="Check"
          size="small"
          onClick={() => {
            setEditMode(false);
            onChange(editedVlaue);
          }}
        >
          <CheckIcon fontSize="small" />
        </IconButton>
        <IconButton
          aria-label="close"
          size="small"
          onClick={() => {
            setEditMode(false);
            setEditedValue(value);
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}
