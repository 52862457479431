import { Box, TextField } from "@mui/material";
import BaseDialog from "./BaseDialog";
import * as React from "react";
import { postBasicInfo } from "../services/basicInfosService";
import { useRecoilState, useSetRecoilState } from "recoil";
import { addBasicInfoDialogAtom } from "../atoms/dialogsAtom";

interface AddDatasetDialogProps {
  handleClose: () => void;
  handleAdd: (content: string) => void;
  open: boolean;
}

export default function AddDatasetDialog({
  handleClose,
  handleAdd,
  open,
}: AddDatasetDialogProps) {
  const [value, setValue] = React.useState<string>("");

  return (
    <BaseDialog
      handleClose={handleClose}
      open={open}
      title="Add Dataset"
      positiveTitle="Add"
      handlePositive={() => {
        handleAdd(value);
      }}
    >
      <Box sx={{ minWidth: "512px", mt: 1 }}>
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </Box>
    </BaseDialog>
  );
}
