import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import queryString from "query-string";
import { basicInfosAtom } from "../../atoms/basicInfosAtom";
import TopAppBar from "../../components/TopAppBar";
import {
  deleteBasicInfo,
  getBasicInfos,
  postBasicInfo,
  updateBasicInfo,
} from "../../services/basicInfosService";
import { columnsAtom } from "../../atoms/columsAtom";
import { getColumns } from "../../services/columnsService";
import BasicTabs from "../../components/BasicTabs";
import { currentColumnTabAtom } from "../../atoms/currentColumnTab";
import BasicTable from "../../components/BasicTable";
import { Fab, IconButton, TableCell, TableRow } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddBasicInfoDialog from "../../components/AddBasicInfoDialog";
import { addBasicInfoDialogAtom } from "../../atoms/dialogsAtom";
import DeleteIcon from "@mui/icons-material/Delete";
import BasicSelect from "../../components/BasicSelect";
import { title } from "process";
import EditableTextField from "../../components/EditableTextField";
import StyledTableCell from "../../components/StyledTableCell";

const BasicInfosPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [basicInfos, setBasicInfos] = useRecoilState(basicInfosAtom);
  const [columns, setColumns] = useRecoilState(columnsAtom);
  const [tabsNames, setTabsNames] = React.useState<string[]>([]);
  const currentTab = useRecoilValue(currentColumnTabAtom);
  const [open, setOpen] = useRecoilState(addBasicInfoDialogAtom);
  // Parse query parameters
  const queryParams = queryString.parse(location.search);
  const page = parseInt(queryParams.page as string) || 1;
  const pageSize = parseInt(queryParams.pagesize as string) || 100;

  const _getBasicInfos = () => {
    if (columns != null && columns.data && tabsNames.length > 0) {
      getBasicInfos(
        page,
        pageSize,
        tabsNames[currentTab] === "All" ? "" : tabsNames[currentTab]
      )
        .then((data) => {
          setBasicInfos(data);
        })
        .catch((error) => console.error(error));
    }
  };

  const _getColumns = () => {
    getColumns(1, 9999)
      .then((data) => {
        setColumns(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    _getColumns();
  }, []);

  useEffect(() => {
    _getBasicInfos();
  }, [page, pageSize, setBasicInfos, currentTab, columns]);

  useEffect(() => {
    if (columns != null && columns.data) {
      setTabsNames([
        ...columns.data.map((column) => column.attributes.title),
        "All",
      ]);
    }
  }, [columns]);

  const handlePageChange = (
    newPage: number,
    newPageSize: number = pageSize
  ) => {
    // Update the URL with new query parameters
    navigate(`/basic-infos?page=${newPage}&pagesize=${newPageSize}`);
  };

  return (
    <div>
      <TopAppBar />
      <AddBasicInfoDialog
        handleAdd={(value: string) => {
          if (columns?.data?.at(currentTab)?.id) {
            postBasicInfo(
              value,
              columns?.data?.at(currentTab)?.id.toString() || ""
            )
              .then(() => {
                _getBasicInfos();
                setOpen(false);
              })
              .catch((error) => console.error(error));
          }
        }}
        handleClose={() => {
          setOpen(false);
        }}
        open={open}
      />
      <BasicTabs tabNames={tabsNames} />
      <BasicTable
        columns={[
          { id: "id", content: "Id", width: "10%" },
          { id: "content", content: "Content", width: "40%" },
          { id: "column", content: "Column", width: "40%" },
          { id: "actions", content: "Actions", width: "10%" },
        ]}
      >
        {basicInfos?.data?.map((basicInfo) => (
          <TableRow key={basicInfo.id} hover>
            <StyledTableCell>{basicInfo.id}</StyledTableCell>
            <StyledTableCell>
              <EditableTextField
                value={basicInfo.attributes.content}
                onChange={(value) => {
                  updateBasicInfo({
                    id: basicInfo.id.toString(),
                    newContent: value,
                  }).then(() => {
                    _getBasicInfos();
                  });
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              {/* {basicInfo.attributes?.column?.data?.attributes?.title} */}
              <BasicSelect
                values={
                  columns?.data?.map((item) => {
                    return {
                      title: item.attributes.title,
                      id: item.id.toString(),
                    };
                  }) || []
                }
                handleChange={(event) => {
                  console.log("Change", event.target.value);
                  updateBasicInfo({
                    id: basicInfo.id.toString(),
                    newColumnId: event.target.value,
                  }).then(() => {
                    _getBasicInfos();
                  });
                }}
                inputLabel="Column"
                currnetValue={
                  basicInfo.attributes?.column?.data?.id.toString() || ""
                }
              />
            </StyledTableCell>
            <StyledTableCell>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  console.log("Delete", basicInfo.id);
                  deleteBasicInfo(basicInfo.id.toString()).then(() => {
                    _getBasicInfos();
                  });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </StyledTableCell>
          </TableRow>
        ))}
      </BasicTable>
      {/* <div>
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        <button onClick={() => handlePageChange(page + 1)}>Next</button>
      </div> */}

      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default BasicInfosPage;
