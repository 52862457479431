import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import BaseDialog from "./BaseDialog";
import * as React from "react";
import { getBasicInfos, postBasicInfo } from "../services/basicInfosService";
import { getComponents } from "../services/componentsService";

interface AddSampleComponentDialogProps {
  handleClose: () => void;
  handleAdd: (components: EntityWrapper<Component>[]) => void;
  sample?: EntityWrapper<Sample>;
  open: boolean;
}

export default function AddSampleComponentDialog({
  handleClose,
  handleAdd,
  open,
}: AddSampleComponentDialogProps) {
  const [components, setComponents] =
    React.useState<ResponseWrapper<EntityWrapper<Component>[]>>();
  const [component, setComponent] = React.useState("");
  const [selectedComponents, setSelectedComponents] = React.useState<
    EntityWrapper<Component>[]
  >([]);

  const _getComponents = () => {
    getComponents(1, 20, component)
      .then((data) => {
        setComponents(data);
      })
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    _getComponents();
  }, [component]);

  return (
    <BaseDialog
      handleClose={handleClose}
      open={open}
      title="Add Components to Sample"
      positiveTitle="Add"
      handlePositive={() => {
        handleAdd(selectedComponents);
      }}
    >
      <Box sx={{ minWidth: "512px", mt: 1 }}>
        <Stack direction={"row"} spacing={1}>
          <Autocomplete
            onChange={(e, value) => {
              if (value) {
                if (
                  value.id &&
                  selectedComponents.filter(
                    (e) => e.id.toString() === value.id.toString()
                  ).length === 0
                ) {
                  setSelectedComponents([...selectedComponents, value]);
                }
              }
            }}
            fullWidth
            // disablePortal
            id="combo-box-demo"
            options={components?.data || []}
            getOptionLabel={(option) => option.attributes.description}
            renderOption={(props, option) => {
              const value = option.attributes.description;
              return (
                <li {...props}>
                  <Typography>{value}</Typography>
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  value={component}
                  onChange={(e) => setComponent(component)}
                  {...params}
                  label="Components"
                  variant="outlined"
                  fullWidth
                />
              );
            }}
          />
          {/* <Button variant="outlined">
            <Typography variant="h6">+</Typography>
          </Button> */}
        </Stack>

        <Stack
          direction={"row"}
          spacing={1}
          flexWrap={"wrap"}
          gap={1}
          sx={{ mt: 2 }}
        >
          {selectedComponents.map((basicInfo) => (
            <Chip
              label={basicInfo.attributes.description}
              key={basicInfo.id}
              onDelete={() => {
                setSelectedComponents(
                  selectedComponents.filter((e) => e.id !== basicInfo.id)
                );
              }}
            />
          ))}
        </Stack>
      </Box>
    </BaseDialog>
  );
}
