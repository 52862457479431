import { atom } from "recoil";

export const instructionsAtom = atom<ResponseWrapper<
  EntityWrapper<Instruction>[]
> | null>({
  key: "instructionsAtom",
  default: null,
});

export const currentInstructionAtom = atom<string>({
  key: "currentInstructionAtom",
  default: "",
});
