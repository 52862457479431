import { useLocation } from "react-router-dom";
import BasicTable from "../../components/BasicTable";
import TopAppBar from "../../components/TopAppBar";
import { useRecoilState } from "recoil";
import { componentsAtom } from "../../atoms/componentsAtom";
import {
  addComponentBasicInfoDialogAtom,
  addComponentDialogAtom,
} from "../../atoms/dialogsAtom";
import { get } from "http";
import {
  deleteComponent,
  getComponents,
  postComponent,
  updateComponent,
} from "../../services/componentsService";
import { useEffect, useState } from "react";
import {
  Chip,
  Fab,
  IconButton,
  Stack,
  TableCell,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Add, Edit } from "@mui/icons-material";
import AddComponentDialog from "../../components/AddComponentDialog";
import AddIcon from "@mui/icons-material/Add";
import EditableTextField from "../../components/EditableTextField";
import AddComponentBasicInfoDialog from "../../components/AddComponentBasicInfoDialog";
import { act } from "react-dom/test-utils";
import StyledTableCell from "../../components/StyledTableCell";

export default function ComponentsPage() {
  const location = useLocation();
  const [components, setComponents] = useRecoilState(componentsAtom);
  const [activeComponent, setActiveComponent] =
    useState<EntityWrapper<Component>>();
  const [open, setOpen] = useRecoilState(addComponentDialogAtom);
  const [openAddBasicInfo, setOpenAddBasicInfo] = useRecoilState(
    addComponentBasicInfoDialogAtom
  );

  const _getComponents = () => {
    console.log("Get components");
    getComponents(1, 9999)
      .then((data) => {
        console.log("Fetched components", data);
        setComponents(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    _getComponents();
  }, []);

  useEffect(() => {
    console.log("Components", components);
  }, [components]);

  return (
    <div>
      <TopAppBar />
      <AddComponentDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleAdd={(
          content: string,
          basicInfos: EntityWrapper<BasicInfo>[]
        ) => {
          postComponent(
            content,
            basicInfos.map((bi) => bi.id.toString())
          )
            .then(() => {
              _getComponents();
              setOpen(false);
            })
            .catch((error) => console.error(error));
        }}
      />
      <AddComponentBasicInfoDialog
        component={activeComponent}
        open={openAddBasicInfo}
        handleClose={() => setOpenAddBasicInfo(false)}
        handleAdd={(basicInfos: EntityWrapper<BasicInfo>[]) => {
          console.log("Selected basic infos", basicInfos);
          if (activeComponent) {
            updateComponent(activeComponent!.id.toString(), undefined, [
              ...activeComponent!.attributes.basic_infos.data.map((bi) =>
                bi.id.toString()
              ),
              ...basicInfos.map((bi) => bi.id.toString()),
            ]).then(() => {
              _getComponents();
            });
          }
          setOpenAddBasicInfo(false);
        }}
      />

      <BasicTable
        columns={[
          { id: "id", content: "Id", width: "5%" },
          { id: "title", content: "Title", width: "35%" },
          { id: "basic_infos", content: "Basic Infos", width: "60%" },
          { id: "actions", content: "Actions", width: "5%" },
        ]}
      >
        {components?.data?.map((component: EntityWrapper<Component>) => (
          <TableRow key={component.id} hover>
            <StyledTableCell>{component.id}</StyledTableCell>
            <StyledTableCell>
              <EditableTextField
                value={component.attributes.description}
                onChange={(newTitle: string) => {
                  updateComponent(component.id.toString(), newTitle).then(
                    () => {
                      _getComponents();
                    }
                  );
                }}
              />
            </StyledTableCell>
            <StyledTableCell>
              <Stack direction={"row"} spacing={1} flexWrap={"wrap"} gap={2}>
                {component.attributes.basic_infos.data.map((basicInfo) => (
                  <Chip
                    label={basicInfo.attributes.content}
                    key={basicInfo.id}
                    onDelete={() => {
                      updateComponent(
                        component.id.toString(),
                        component.attributes.description,
                        component.attributes.basic_infos.data
                          .filter((bi) => bi.id !== basicInfo.id)
                          .map((bi) => bi.id.toString())
                      ).then(() => {
                        _getComponents();
                      });
                    }}
                  />
                ))}
                <IconButton
                  aria-label="add"
                  size="small"
                  onClick={() => {
                    setOpenAddBasicInfo(true);
                    setActiveComponent(component);
                  }}
                >
                  <Add />
                </IconButton>
              </Stack>
            </StyledTableCell>
            <StyledTableCell>
              <IconButton
                aria-label="delete"
                size="small"
                onClick={() => {
                  console.log("Delete column", component.id);
                  deleteComponent(component.id.toString()).then(() => {
                    _getComponents();
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </StyledTableCell>
          </TableRow>
        ))}
      </BasicTable>
      <Fab
        color="primary"
        aria-label="add"
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
