import axios from "axios";
import handleAxiosError from "./axiosError";
import { BASE_URL } from "../utils/constants";

// Utility to build common API call parameters
const buildAPIParams = (
  page: number,
  pageSize: number,
  columnTitle?: string,
  content?: string
) => ({
  pagination: { page, pageSize },
  filters: columnTitle
    ? { column: { title: { $eq: columnTitle } } }
    : undefined,
  filter: content ? { content: { $regex: content } } : undefined,
  populate: "*",
});

export async function getBasicInfos(
  page = 1,
  pageSize = 10,
  columnTitle?: string,
  content?: string
) {
  try {
    const params = buildAPIParams(page, pageSize, columnTitle, content);
    const { data } = await axios.get(BASE_URL + "/basic-infos", {
      params,
    });
    return data;
  } catch (error) {
    console.error("Error fetching basic infos:", error);
    return handleAxiosError(error);
  }
}

export async function postBasicInfo(content: string, column: string) {
  try {
    const { data } = await axios.post(BASE_URL + "/basic-infos", {
      data: { content, column },
    });
    return data;
  } catch (error) {
    console.error("Error posting basic info:", error);
    return handleAxiosError(error);
  }
}

export async function deleteBasicInfo(id: string) {
  try {
    const { data } = await axios.delete(BASE_URL + `/basic-infos/${id}`);
    return data;
  } catch (error) {
    console.error("Error deleting basic info:", error);
    return handleAxiosError(error);
  }
}

interface UpdateBasicInfoParams {
  id: string;
  newContent?: string;
  newColumnId?: string;
}

export async function updateBasicInfo({
  id,
  newContent: content,
  newColumnId: column,
}: UpdateBasicInfoParams) {
  try {
    const { data } = await axios.put(BASE_URL + `/basic-infos/${id}`, {
      data: { content, column },
    });
    return data;
  } catch (error) {
    console.error("Error updating basic info:", error);
    return handleAxiosError(error);
  }
}
