import { Box, CircularProgress, IconButton } from "@mui/material";
import EditableTextField from "../../../components/EditableTextField";
import { updateSample } from "../../../services/samplesService";
import { getCaptionForImage } from "../../../services/getCaptionForImage";
import { Replay } from "@mui/icons-material";
import { useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  currentInstructionAtom,
  instructionsAtom,
} from "../../../atoms/instructionsAtom";

interface PromptProps {
  sample: EntityWrapper<Sample>;
  _getSamples: () => void;
}

export default function Prompt({ sample, _getSamples }: PromptProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [currentInstruction, setCurrentInstruction] = useRecoilState(
    currentInstructionAtom
  );
  const instructions = useRecoilValue(instructionsAtom);
  return (
    <Box sx={{ display: "flex" }}>
      <EditableTextField
        value={sample.attributes.prompt}
        onChange={(value) => {
          updateSample(
            sample.id.toString(),
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            value
          ).then(() => {
            _getSamples();
          });
        }}
      />
      <Box>
        <IconButton
          disabled={loading}
          onClick={() => {
            const instructionId = currentInstruction;
            if (!instructionId) {
              return;
            }
            const instruction = instructions?.data?.find((i) => {
              console.log("i.id", i.id);
              console.log("instructionId", instructionId);
              return i.id === parseInt(instructionId);
            });
            console.log("instructions", instructions);
            console.log("instruction", instruction);

            if (!instruction) {
              return;
            }
            setLoading(true);
            getCaptionForImage(
              sample.attributes.photo.data?.attributes.url ?? "",
              instruction.attributes.content
            ).then((prompt) => {
              updateSample(
                sample.id.toString(),
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                prompt
              ).then(() => {
                setLoading(false);
                _getSamples();
              });
            });
          }}
        >
          {loading ? <CircularProgress size={20} /> : <Replay />}
        </IconButton>
      </Box>
    </Box>
  );
}
