import { Add } from "@mui/icons-material";
import { Stack, Chip, IconButton, Box, colors } from "@mui/material";
import { updateSample } from "../../../services/samplesService";

interface BasicInfosChipsProps {
  sample: EntityWrapper<Sample>;
  _getSamples: () => void;
  setOpenAddBasicInfo: (open: boolean) => void;
  setActiveSample: (sample: EntityWrapper<Sample>) => void;
}

export default function BasicInfosChips({
  sample,
  _getSamples,
  setOpenAddBasicInfo,
  setActiveSample,
}: BasicInfosChipsProps) {
  return (
    <Stack direction={"row"} spacing={1} flexWrap={"wrap"} gap={1}>
      {sample.attributes.basic_infos.data?.map((bi) => (
        <Chip
          key={bi.id}
          label={
            <Box sx={{ display: "flex", gap: 0.5 }}>
              <Box sx={{ color: colors.blue[200] }}>
                {bi.attributes.column?.data.attributes.title}
              </Box>
              <Box>|</Box>
              <Box> {bi.attributes.content}</Box>
            </Box>
          }
          onDelete={() => {
            updateSample(
              sample.id.toString(),
              undefined,
              undefined,
              sample.attributes.basic_infos.data
                .filter((b) => b.id !== bi.id)
                .map((b) => b.id.toString())
            ).then(() => {
              _getSamples();
            });
          }}
        />
      ))}
      <IconButton
        aria-label="add"
        size="small"
        onClick={() => {
          setOpenAddBasicInfo(true);
          setActiveSample(sample);
        }}
      >
        <Add />
      </IconButton>
    </Stack>
  );
}
