import axios, { AxiosError, AxiosResponse } from "axios";
import handleAxiosError from "./axiosError";
import { BASE_URL } from "../utils/constants";

// Utility to build common API call parameters
const buildAPIParams = (page: number, pageSize: number) => ({
  pagination: { page, pageSize },
  populate: "*",
  sort: ["title"],
});

export async function getInstructions(page = 1, pageSize = 10) {
  try {
    const params = buildAPIParams(page, pageSize);

    const res = await axios.get(BASE_URL + "/instructions", {
      params,
    });
    return res.data;
  } catch (error) {
    console.error("Error fetching instructions:", error);
    return handleAxiosError(error);
  }
}

export async function postInstruction(title: string, content: string) {
  try {
    const res = await axios.post(BASE_URL + "/instructions", {
      data: { title, content },
    });
    return res.data;
  } catch (error) {
    console.error("Error posting instruction:", error);
    return handleAxiosError(error);
  }
}

export async function deleteInstruction(id: string) {
  try {
    const res = await axios.delete(BASE_URL + `/instructions/${id}`);
    return res.data;
  } catch (error) {
    console.error("Error deleting instruction:", error);
    return handleAxiosError(error);
  }
}

export async function updateInstruction(
  id: string,
  title?: string,
  content?: string
) {
  try {
    const res = await axios.put(BASE_URL + `/instructions/${id}`, {
      data: { title, content },
    });
    return res.data;
  } catch (error) {
    console.error("Error updating instruction:", error);
    return handleAxiosError(error);
  }
}
