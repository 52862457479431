import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Stack,
  TextField,
  Typography,
  colors,
} from "@mui/material";
import BaseDialog from "./BaseDialog";
import * as React from "react";
import { getBasicInfos, postBasicInfo } from "../services/basicInfosService";
import { getDataset } from "../services/datasetService";

interface AddSampleDatasetDialogProps {
  handleClose: () => void;
  handleAdd: (basicInfos: EntityWrapper<Dataset>[]) => void;
  sample?: EntityWrapper<Sample>;
  open: boolean;
}

export default function AddSampleBasicInfoDialog({
  handleClose,
  handleAdd,
  open,
}: AddSampleDatasetDialogProps) {
  const [datasets, setDatasets] =
    React.useState<ResponseWrapper<EntityWrapper<Dataset>[]>>();
  const [dataset, setDataset] = React.useState("");
  const [selectedDatasets, setSelectedDatastes] = React.useState<
    EntityWrapper<Dataset>[]
  >([]);
  const _getBasicInfos = () => {
    getDataset(1, 20, dataset)
      .then((data) => {
        setDatasets(data);
      })
      .catch((error) => console.error(error));
  };

  React.useEffect(() => {
    _getBasicInfos();
  }, [dataset]);

  return (
    <BaseDialog
      handleClose={handleClose}
      open={open}
      title="Add Sample to Datasets"
      positiveTitle="Add"
      handlePositive={() => {
        handleAdd(selectedDatasets);
      }}
    >
      <Box sx={{ minWidth: "512px", mt: 1 }}>
        <Stack direction={"row"} spacing={1}>
          <Autocomplete
            onChange={(e, value) => {
              if (value) {
                if (
                  value.id &&
                  selectedDatasets.filter(
                    (e) => e.id.toString() === value.id.toString()
                  ).length === 0
                ) {
                  setSelectedDatastes([...selectedDatasets, value]);
                }
              }
            }}
            fullWidth
            // disablePortal
            id="combo-box-demo"
            options={datasets?.data || []}
            getOptionLabel={(option) => option.attributes.name}
            renderOption={(props, option) => {
              const value = option.attributes.name;
              return (
                <li {...props}>
                  <Box sx={{ display: "flex", gap: 0.5 }}>
                    <Box> {value}</Box>
                  </Box>
                </li>
              );
            }}
            renderInput={(params) => {
              return (
                <TextField
                  value={dataset}
                  onChange={(e) => setDataset(dataset)}
                  {...params}
                  label="Basic Infos"
                  variant="outlined"
                  fullWidth
                />
              );
            }}
          />
          {/* <Button variant="outlined">
            <Typography variant="h6">+</Typography>
          </Button> */}
        </Stack>

        <Stack
          direction={"row"}
          spacing={1}
          flexWrap={"wrap"}
          gap={1}
          sx={{ mt: 2 }}
        >
          {selectedDatasets.map((dataset) => (
            <Chip
              label={
                <Box sx={{ display: "flex", gap: 0.5 }}>
                  <Box> {dataset.attributes.name}</Box>
                </Box>
              }
              key={dataset.id}
              onDelete={() => {
                setSelectedDatastes(
                  selectedDatasets.filter((e) => e.id !== dataset.id)
                );
              }}
            />
          ))}
        </Stack>
      </Box>
    </BaseDialog>
  );
}
